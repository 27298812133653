import React from 'react'
import AboutLinkList from '../components/Header/Navigation/LinksLists/AboutLinkList'
import ServicesLinkList from '../components/Header/Navigation/LinksLists/ServicesLinkList'
import SocialLinkList from '../components/Header/Navigation/LinksLists/SocialLinkList'

import LandingPageLayout from '../components/Layouts/LandingPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import * as styles from './../styles/pageSpecific/landings.module.scss'

const SiteMapPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Site',
    title2: 'Map',
    breadcrumbs: [''],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <LandingPageLayout pageData={pageInfo}>
      <div className="content">
        <SectionContainer
          id=""
          color=""
          type="contained"
          classes={['inlineChildren_column', 'alignChildrenCenter']}
        >
          <h1>Site Map</h1>
        </SectionContainer>
        <SectionContainer
          id=""
          color=""
          type="contained"
          classes={['inlineChildren_mobile']}
        >
          <section className={`${styles.sitemap_linkList}`}>
            <h4>Meet Precise</h4>
            <AboutLinkList />
          </section>
          <section
            className={`${styles.sitemap_linkList} ${styles.sitemap_servicesList}`}
          >
            <h4>Our Capabilities</h4>
            <ServicesLinkList />
          </section>
          <section className={`${styles.sitemap_linkList}`}>
            <h4>Other Important Links</h4>
            <SocialLinkList notSitemap={false} />
          </section>
        </SectionContainer>
      </div>
    </LandingPageLayout>
  )
}

export default SiteMapPage
