// extracted by mini-css-extract-plugin
export var large = "landings-module--large--d7a9c";
export var left = "landings-module--left--a9f36";
export var lighter = "landings-module--lighter--1525c";
export var med = "landings-module--med--ff846";
export var narrowContent = "landings-module--narrowContent--fb5d4";
export var scrollingBox = "landings-module--scrollingBox--b30c5";
export var scrollingBox_content = "landings-module--scrollingBox_content--ab6d8";
export var signiture = "landings-module--signiture--a5432";
export var sitemap_linkList = "landings-module--sitemap_linkList--c29da";
export var sitemap_servicesList = "landings-module--sitemap_servicesList--53580";