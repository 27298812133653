import React, { useState, useEffect, useCallback } from 'react'
import Seo from '../Header/SEO/Seo'
import { Link } from 'gatsby'
// import Header from './../Header/Header'
import * as styles from './../Header/Header.module.scss'
import MobileNavigation from './../Header/Navigation/MobileNavigation/MobileNavigation'
import Overlay from '../UI/Overlay/Overlay'
import Navigation from './../Header/Navigation/Navigation'

import PreciseLogo from './../../images/logos/Precise_Logo2.inline.svg'

const LandingPage = ({ children, pageData }) => {
  const [navOpen, setNavOpen] = useState(false)

  const openMobileNavHandler = (e) => {
    e.preventDefault()
    setNavOpen((prevState) => !prevState)
  }
  const onWindowsClickHandler = useCallback((e) => {
    // e.preventDefault()
    if (window.innerWidth < 1020) {
      if (
        e.target.id !== 'mobileNav' &&
        e.target.id !== 'mobileNav__toggle' &&
        !document.getElementById('mobileNav').contains(e.target)
      ) {
        setNavOpen((prevState) => !prevState)
      }
    } else {
      setNavOpen(false)
    }
  }, [])

  useEffect(() => {
    if (navOpen) {
      document.querySelector('body').classList.add('fixed')
    } else {
      document.querySelector('body').classList.remove('fixed')
    }
  }, [navOpen])

  useEffect(() => {
    if (navOpen) {
      window.addEventListener('click', onWindowsClickHandler)
    }
    return () => {
      window.removeEventListener('click', onWindowsClickHandler)
    }
  }, [navOpen, onWindowsClickHandler])

  return (
    <article
      id="mainContent"
      className={`landingLayout ${navOpen ? 'active_nav' : ''}`}
    >
      <Seo title={`${pageData.title1} ${pageData.title2}`} />
      {/* <Header pageData={pageData} pageType={pageData.pageType} mobileNavHandler={openMobileNavHandler} activeMobileNav={navOpen}/> */}
      <header>
        <article className={styles.header__container}>
          <section className={styles.header__logoBar}>
            <Link
              aria-label="Link Home"
              to="/"
              className={styles.header__logoWrap}
            >
              <PreciseLogo
                className={styles.header__logo}
                alt="Precise Systems Inc Logo"
              />
            </Link>
            <Navigation
              mobileNavHandler={openMobileNavHandler}
              activeMobileNav={navOpen}
            />
          </section>
        </article>
      </header>
      <main>{children}</main>
      <Overlay />
      <MobileNavigation activeClass={navOpen ? 'active_nav' : ''} />
    </article>
  )
}

export default LandingPage
